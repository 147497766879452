
/*HEADER*/
.header{position:relative;box-sizing: initial;width:100%;height:auto;background:#fff;display:flex;justify-content:flex-start;align-items:flex-start;flex-direction:column;box-sizing: initial;margin:initial;z-index: 500;font-size:14px;}
.header .rela{background:#fff;position:relative;z-index:20;box-sizing: initial;width:1340px;height:100px;overflow:hidden;margin:0 auto;display:flex;justify-content:space-between;align-items:center;}
.header ul{margin:0;}
.header .rela h1{margin-top:20px;}
.header .rela h1 a{}
.header .rela .sideItemWrap{display:flex;justify-content:flex-end;align-items:flex-end;flex-direction:column;cursor: pointer;}
.header .rela .sideItemWrap .linkList{display:flex;justify-content:flex-start;align-items:flex-start;}
.header .rela .sideItemWrap .linkList li{display:flex;margin-left:24px;}
.header .rela .sideItemWrap .linkList li a{font-size:14px;color:#000;}
.header .rela .sideItemWrap .outloginItemWrap{margin-top:20px;display:flex;justify-content:flex-start;align-items:center;}
.header .rela .sideItemWrap .outloginItemWrap .inp{font-size:16px;color:#000;font-weight:500;box-sizing: initial;width:calc( 150px - 2px - 26px );padding:0 13px;border:1px solid #5fb0b4;border-radius:8px;}
.header .rela .sideItemWrap .outloginItemWrap .inp + .inp{margin-left:6px;}
.header .rela .sideItemWrap .outloginItemWrap label{margin-left:6px;display:flex;justify-content:flex-start;align-items:flex-start;margin-bottom: 0;}
.header .rela .sideItemWrap .outloginItemWrap label input{display:flex;}
.header .rela .sideItemWrap .outloginItemWrap label span{margin-left:8px;display:flex;font-size:14px;color:#111;}
.header .rela .sideItemWrap .outloginItemWrap .btnLogin{margin-left:24px;box-sizing: initial;width:120px;height:34px;overflow:hidden;border:0;background:#06b3ba url(../../assetsNew/image/bg/bg-login.png) no-repeat 20px center;padding-left:43px;display:flex;justify-content:flex-start;align-items:center;font-size:15px;color:#fff;font-weight:500;box-sizing: border-box;}

.header .rela .sideItemWrap .btnUserEdit{display:flex;justify-content:flex-start;align-items:center;}
.header .rela .sideItemWrap .btnUserEdit img{display:flex;}
.header .rela .sideItemWrap .btnUserEdit span{display:flex;margin-left:5px;font-size:14px;color:#000;}
.header .rela .sideItemWrap .outloginItemWrap .btnUser{display:flex;justify-content:flex-start;align-items:center;}
.header .rela .sideItemWrap .outloginItemWrap .btnUser .upic{box-sizing: initial;width:34px;height:34px;overflow:Hidden;border-radius:50%;background:url(../../assetsNew/image/ico/ico-upic.png) no-repeat center center;}
.header .rela .sideItemWrap .outloginItemWrap .btnUser .uname{margin-left:8px;font-size:16px;color:#111;font-weight:500;}
.header .rela .sideItemWrap .outloginItemWrap .btnUser .uname strong{font-size:16px;color:#06aeb5;font-weight:500;}
.header .rela .sideItemWrap .outloginItemWrap .timer{margin-left:8px;font-size:16px;color:#666;font-weight:500;}
.header .rela .sideItemWrap .outloginItemWrap .btnExtension.on{box-sizing: initial;width:90px;height:34px;border:0;padding:0;background:#06b3ba;display:flex;justify-content:center;align-items:center;font-size:15px;color:#fff;font-weight:500;margin-left:10px;}
.header .rela .sideItemWrap .outloginItemWrap .btnExtension{box-sizing: initial;width:90px;height:34px;border:0;padding:0;background:#85a2a3;display:flex;justify-content:center;align-items:center;font-size:15px;color:#fff;font-weight:500;margin-left:10px;}
.header .rela .sideItemWrap .outloginItemWrap .btnLogout{margin-left:6px;box-sizing: initial;width:120px;height:34px;overflow:hidden;border:0;background:#85a2a3 url(../../assetsNew/image/bg/bg-login.png) no-repeat 20px center;padding-left:43px;display:flex;justify-content:flex-start;align-items:center;font-size:15px;color:#fff;font-weight:500;box-sizing: border-box;}

/************************
.header .gnbItemWrap 반영 유의
운영 색상 background:#fff;
개발 색상 background:#88b749; 
개발oracle 색상 background:#f75c5c; 
*************************/
.header .gnbItemWrap{background:#fff;
    position:relative;z-index:200;box-sizing: initial;width:100%;height:50px;overflow:hidden;border-top:1px solid #dce0df;border-bottom:3px solid #06b3ba;}
.header .gnbItemWrap ul{box-sizing: initial;max-width:1340px;height:50px;overflow:hidden;margin:0 auto;display:flex;justify-content:center;align-items:center;}
.header .gnbItemWrap ul li{box-sizing: initial;width:230px;display:flex;justify-content:flex-start;align-items:center;height: 100%;}
.header .gnbItemWrap ul li{transition: all 0.5s;font-size:18px;color:#111;font-weight:500;cursor: pointer;}
.header .gnbItemWrap ul li:hover{color:#00a1a8;}
.totalmenuItemWrap{transition:all .5s;transform:translateY(-350px);position:absolute;left:0;top:150px;box-sizing: initial;width:100%;overflow:hidden;background:#fff;border-bottom:1px solid #60ced3;opacity:0;box-sizing: initial;}
.totalmenuItemWrap.on{transform:translateY(0px);opacity:1;z-index:400;height:max-content;min-width: 1340px;}
.totalmenuItemWrap.on>ul{transform:translateY(0px);opacity:1;z-index:400;height:max-content;}
.totalmenuItemWrap.on:hover{transform:translateY(0px);opacity:1;z-index:400;}
.totalmenuItemWrap>ul{box-sizing: initial;width:1340px;height: 154px;overflow:hidden;margin:0 auto;display:flex;justify-content:center;align-items:flex-start;}
.totalmenuItemWrap.on:hover>ul{box-sizing: initial;height:max-content;}
.totalmenuItemWrap>ul>li{box-sizing: initial;width:230px;display:flex;justify-content:flex-start;align-items:flex-start;height: max-content;}
.totalmenuItemWrap>ul>li>ul{box-sizing: initial;width:100%;height:auto;overflow:Hidden;margin-top:24px;margin-bottom:24px;}
.totalmenuItemWrap>ul>li>ul>li{box-sizing: initial;width:100%;height:auto;overflow:Hidden;}
.totalmenuItemWrap>ul>li>ul>li + li{margin-top:12px;}
.totalmenuItemWrap>ul>li>ul>li>.anchor{font-size:16px;color:#4d4d4d;cursor: pointer;}
.totalmenuItemWrap>ul>li>ul>li>.anchor:hover{color:#48bfc5;font-weight:500;}
/* .totalmenuItemWrap>ul>li>ul>li>a{font-size:16px;color:#4d4d4d;}
.totalmenuItemWrap>ul>li>ul>li>a:hover{color:#48bfc5;font-weight:500;} */


/*소제목*/
.subHeaderWrap{box-sizing: initial;
    /* max-width:1350px; */
    /* width: 80vw; */
    height:29px;overflow:hidden;margin:26px auto 0 auto;display:flex;justify-content:space-between;align-items:center;}
.subHeaderWrap .stit{display:flex;justify-content:flex-start;align-items:center;}
.subHeaderWrap .stit img{display:flex;}
.subHeaderWrap .stit strong{display:flex;font-size:20px;color:#111;font-weight:500;margin-left:10px;}
.subHeaderWrap ul{display:flex;justify-content:flex-start;align-items:center;margin:0;}
.subHeaderWrap ul li{background:url(../../assetsNew/image/bg/bg-loc-arr.png) no-repeat 10px center;display:flex;justify-content:flex-start;align-items:center;padding-left:28px;position:relative;font-size:15px;color:#666;}
.subHeaderWrap ul li:nth-child(1){background:transparent; cursor: pointer;}
/* .subHeaderWrap ul li:nth-child(1),
.subHeaderWrap ul li:nth-child(2){background:transparent;} */
.subHeaderWrap ul li strong{font-size:15px;color:#06b3ba;font-weight:500;}